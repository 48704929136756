import { useEffect, useState } from "react";
import { addDays, differenceInDays, format, parseISO } from "date-fns";

// API Service
import { getArticlesMetadata } from "../../../apiService";
import { formatDate, getOldestArticleDate } from "../utils";

// Reusable components
import MetricGraph from "../../../components/MetricGraph";

const dropdownCategories = ["Article", "Video"];
const DATE_FORMAT = "MM/dd/yyyy";

const GraphComponent = () => {
  // MetricGraph Component States
  const [articleCategory, setArticleCategory] = useState("");
  const [datePickerStartValue, setDatePickerStartValue] = useState(null);
  const [datePickerEndValue, setDatePickerEndValue] = useState(null);
  const [allArticlesMetadata, setAllArticlesMetadata] = useState([]);
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    // Generate graph data only if at least one of the following states is set
    if (articleCategory || datePickerStartValue || datePickerEndValue) {
      generateGraphData();
    }
  }, [articleCategory, datePickerStartValue, datePickerEndValue]);

  const generateGraphData = async () => {
    const shouldFetchArticlesMetadata = !allArticlesMetadata[articleCategory]?.length;
    let articlesMetadata = [];
    if (shouldFetchArticlesMetadata) {
      articlesMetadata = await fetchArticlesMetadata();
      setAllArticlesMetadata((prevState) => ({
        ...prevState,
        [articleCategory]: articlesMetadata,
      }));
    } else {
      articlesMetadata = allArticlesMetadata[articleCategory];
    }
    if (articlesMetadata.length === 0) {
      setGraphData([]);
      return;
    }
    const { startDate, endDate } = getDateRange(articlesMetadata);
    const labels = generateLabels(startDate, endDate);
    const articlesByDate = groupArticlesByDate(articlesMetadata);
    const totalArticlesUpToStart = calculateTotalUpToDate(articlesByDate, startDate);
    const graphData = calculateCumulativeData(labels, articlesByDate, totalArticlesUpToStart);
    setGraphData(graphData);
  };

  const fetchArticlesMetadata = async () => {
    return await getArticlesMetadata({
      articleCategory,
    });
  };

  const getDateRange = (articlesMetadata) => {
    const startDate = datePickerStartValue
      ? new Date(datePickerStartValue)
      : getOldestArticleDate(articlesMetadata);
    const endDate = datePickerEndValue
      ? new Date(datePickerEndValue)
      : new Date();
    return { startDate, endDate };
  };

  const generateLabels = (startDate, endDate) => {
    const daysDiff = differenceInDays(endDate, startDate);
    return Array.from({ length: daysDiff + 1 }, (_, i) =>
      format(addDays(startDate, i), DATE_FORMAT)
    );
  };

  const groupArticlesByDate = (articlesMetadata) => {
    return articlesMetadata.reduce((acc, article) => {
      const articleDate = format(parseISO(article.created_date), DATE_FORMAT);
      acc[articleDate] = (acc[articleDate] || 0) + 1;
      return acc;
    }, {});
  };

  const calculateCumulativeData = (labels, articlesByDate, totalArticlesUpToStart) => {
    let totalArticlesAvailable = totalArticlesUpToStart;
    return labels.map((label) => {
      totalArticlesAvailable += articlesByDate[label] || 0;
      return {
        label: label,
        amount: totalArticlesAvailable,
      };
    });
  };

  const calculateTotalUpToDate = (articlesByDate, startDate) => {
    let total = 0;
    Object.keys(articlesByDate).forEach((date) => {
      const articleDate = new Date(date);
      if (articleDate < startDate) {
        total += articlesByDate[date];
      }
    });
    return total;
  };

  return (
    <MetricGraph
      title="Articles Analytics"
      graphProps={{
        domain: ['dataMin - 1', 'dataMax + 1'],
        type:"area",
        show: true,
        xDataKey: "label",
        lineDataKey: "amount",
        lineColor: "#8884d8",
        lineType: "monotone",
        xAxisLabel: "Period",
        yAxisLabel: "Amount",
        height: 300,
        data: graphData,
      }}
      dropdownProps={{
        show: true,
        label: "Article Type",
        onChange: setArticleCategory,
        value: articleCategory,
        items: dropdownCategories,
      }}
      datePickersProps={[
        {
          show: true,
          label: "Start Date",
          onChange: setDatePickerStartValue,
          value: datePickerStartValue,
          format: DATE_FORMAT,
        },
        {
          show: true,
          label: "End Date",
          onChange: setDatePickerEndValue,
          value: datePickerEndValue,
          format: DATE_FORMAT,
        },
      ]}
    />
  );
};

export default GraphComponent;
