import React from "react";
import { Box, Typography, Grid, Card } from "@material-ui/core";

const MetricBox = ({ data }) => {
  return (
    <Card elevation={3}>
      <Box p={3}>
        <Typography variant="h6" gutterBottom>
          {data.Title}
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 15 }}>
          {data.Metrics.map((metric, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Typography variant="subtitle1" color="textSecondary">
                {metric.metricName}
              </Typography>
              <Box
                sx={{
                  margin: "10px auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">{metric.metricValue}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
  );
};

export default MetricBox;
