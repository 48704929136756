import React from 'react';

// MATERIAL-UI COMPONENTS
import { Container, Grid } from '@material-ui/core';

// Reusable components
import GraphComponent from './DashboardComponents/GraphComponent';
import BoxesComponents from './DashboardComponents/BoxesComponent';


function AnalyticsDashboardComponent() {

    return <Container style={{ marginTop: '25px' }} >
        <h1>Analytics Dashboard Page Template</h1>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <BoxesComponents />
            </Grid>
            <Grid item xs={12} >
                <GraphComponent />
            </Grid>
        </Grid>
    </Container>;
}

export default AnalyticsDashboardComponent;