import { useEffect, useState } from "react";

// Reusable components
import MetricBox from "../../../components/MetricBox";

// API Service
import { getArticlesMetadata } from "../../../apiService";
import { getBoxesPeriodData, setMetadataPeriodData } from "../utils";

const BoxesComponents = () => {
  const [videosUploadedData, setVideosUploadedData] = useState({
    Title: "Videos Uploaded",
    Metrics: [],
  });
  const [articlesPublishedData, setArticlesPublishedData] = useState({
    Title: "Articles Published",
    Metrics: [],
  });

  useEffect(() => {
    loadArticlesData();
  }, []);

  const loadArticlesData = async () => {
    const [videoMetadata, articleMetada] = await Promise.allSettled([
      getArticlesMetadata({
        articleCategory: "Video",
      }),
      getArticlesMetadata({
        articleCategory: "Article",
      }),
    ]);
    if (videoMetadata.status === "fulfilled") {
      const videoMetadataInfo = getBoxesPeriodData(videoMetadata.value);
      setMetadataPeriodData(videoMetadataInfo, setVideosUploadedData);
    }
    if (articleMetada.status === "fulfilled") {
      const articleMetadaInfo = getBoxesPeriodData(articleMetada.value);
      setMetadataPeriodData(articleMetadaInfo, setArticlesPublishedData);
    }
  };

  return (
    <>
      <MetricBox data={videosUploadedData} />
      <MetricBox data={articlesPublishedData} />
    </>
  );
};

export default BoxesComponents;
